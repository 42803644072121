import { Component } from '@angular/core';
import { ThemeService } from '../theme.service';
import { ActivatedRoute } from '@angular/router';
import { PlanService } from '../services/plan/plan.service';
import { ImagesService } from '../services/image/images.service';
import { WebService } from '../services/web.service';
import { NotificationService } from '../services/notification.service';
import { LoaderService } from '../services/loader.service';
import { Plan } from '../components/plan/model/plan.model';
@Component({
  selector: 'app-practic',
  templateUrl: './practic.component.html',
  styleUrls: ['./practic.component.scss']
})
export class PracticComponent {
  selectedPalette: string = 'Billcentrix';

  // plans = [{name :  "Plan 1", price : 15, description : "Descripción para el plan 1"}, {name :  "Plan 2", price : 25, description : "Descripción para el plan 2"}, {name :  "Plan 3", price : 35, description : "Descripción para el plan 3"}];
  plans : Plan[] = []
  // TENDRA VALORES POR DEFECTO EN CASO DE QUE NO SE ENCUENTRE NADA EN LA BUSQUEDA DE LOS DOMINIOS
  title : any = "";
  description : any = "";
  price_title : any = "";
  banner_title : any = "";
  banner_description : any = "";
  class : any = "pal1";
  imagePrincipal : any ;
  imagePromotional : any = null;
  isSuscriptionActive = true;
  aloudNavigation = true;

  constructor(public themeService: ThemeService,private activateRoute : ActivatedRoute, private planService : PlanService, private imageService: ImagesService, private web : WebService, private notification : NotificationService, private loader : LoaderService) {
    this.loader.open();
    this.activateRoute.params.subscribe( params =>{

        // ESTO ES PARA VALIDAR SI ES UNA VISTA PREVIA O SE ESTA CARGANDO UN PORTAL YA CREADO
        let param = params["id"] != undefined ? params["id"] : -1;
        this.aloudNavigation = param == -1 ? true : false;
        if(!this.aloudNavigation){ // ESTO ES SOLO PARA VALIDAR SI ES UNA VISTA PREVIA - NO PERMITE LA NAVEGACION
          this.notification.showInfo("Este modo NO permite generar suscripciones")
        }
        // ESTA LINEA DE ABAJO ES PARA TESTING LOCAL SOLAMENTE - CARGA UNA CONFIGURACION QUE YA ESTA COMPLETA
        // this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "sof.billcentric.com" + "/"  + -1).subscribe( response =>{ 
        //  this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "consultingservices.billcentrix.com" + "/"  + -1).subscribe( response =>{
        // this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "dev.onboarding.practic.billcentric.com" + "/"  + 235).subscribe( response =>{ 
        // LA LINEA QUE DEBE SUBIRSE DESCOMENTADA ES LA DE ABAJO
         //this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "nosenosenose.billcentric.com" + "/"  + -1).subscribe(response => {  
        this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/"  + param ).subscribe( response =>{
          this.showContentPage(response);
          this.loader.close();
        }, err =>{
          this.loader.close();
          this.notification.showError(err);
        });
    });
  }

  showContentPage(response: any){ // ESTA FUNCION ES PARA VALIDAR SI SE MUESTRA EL CONTENIDO O NO DEL PORTAL - TOMA EN CUENTA SI LA SUSCRIPCION ESTA ACTIVA  Y SI LA FECHA DE PROXIMO COBRO ES FUTURA
    if(response.result.onboardingImages != null){
      this.imagePrincipal = response.result.onboardingImages.principal_banner;
      if(response.result.customerHasPlan != null){
        var nexBillDate = new Date(response.result.customerHasPlan.next_bill_date);
        var today = new Date();
        var isValidDate = nexBillDate < today;
        if(response.result.customerHasPlan != null && (response.result.customerHasPlan.status_customerPlan !== 1 || !isValidDate)){
          this.isSuscriptionActive = false
        }
        this.planService.isSuscriptionActive.emit(this.isSuscriptionActive);
        this.show(response);      
      }else{
        this.planService.isSuscriptionActive.emit(this.isSuscriptionActive);
        this.show(response);
      }
    }else{
      this.loader.close();
      this.notification.showInfo("Por favor culmina tu portal...")
    }
    
  }

  show(response : any){ // ESTO MUESTRA EL CONTENIDO DEL PORTAL
    
    for(let plan of response.result.plans){
      if(plan.status_plan == 1){ // MOSTRAMOS SOLO PLANES ACTIVOS
        this.plans.push(new Plan(plan.name_plan, plan.feeCost_plan, plan.description, plan.url_image, plan.id, plan.service,plan.is_visible_plan));
      }
    }
    this.planService.obtainPlans.emit(this.plans);
    this.imagePrincipal = response.result.onboardingImages.principal_banner;
    this.imagePromotional = response.result.onboardingImages.promotional_banner
    localStorage.setItem("service", response.result.service.id);
    localStorage.setItem("partner",response.result.partner.id); // GUARDAMOS EL PARTNER AL QUE PERTENECE EL TEMPLATE
    localStorage.setItem("step",this.isSuscriptionActive.toString()); // GUARDAMOS EL STEP AL QUE PERTENECE EL TEMPLATE
    this.imageService.setLogoImage(response.result.onboardingImages.logo);
    this.imageService.logoImage.emit(response.result.onboardingImages.logo);
    this.imageService.setPrincipalBannerImage(response.result.onboardingImages.principal_banner);
    this.class = response.result.color;
    this.themeService.setPalette(response.result.color);
    this.title = response.result.onboardingPortalContent.page_title;
    this.description = response.result.onboardingPortalContent.description_page;
    this.price_title = response.result.onboardingPortalContent.price_title;
    this.banner_title = response.result.onboardingPortalContent.banner_title;
    this.banner_description = response.result.onboardingPortalContent.description_banner;
  }

 
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
  
}
